@import '../../../../scss/theme-bootstrap';

.sku-menu {
  &-chip {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    border: none;
    z-index: 0;
    @if $pdp_modernized_design == true {
      margin-top: 0;
    } @else {
      margin-top: 20px;
    }
    &__single {
      @if $pdp_modernized_design == true {
        margin-top: 0;
      } @else {
        margin-top: 20px;
      }
    }
    &__option {
      display: flex;
      flex-basis: 32%;
      border: 2px solid transparent;
      outline: 1px solid $gray;
      white-space: pre-wrap;
      line-height: 1.3;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      height: 58px;
      @include breakpoint($landscape-up) {
        line-height: 1.5;
      }
      @if $pdp_modernized_design == true {
        padding: 0 12px;
        p {
          font-size: 12px;
          line-height: get-line-height(12px, 15px);
          @include breakpoint($landscape-up) {
            font-size: 14px;
            line-height: 1.5;
          }
        }
      } @else {
        text-align: center;
        padding: 0 5px;
      }
      &.selected {
        border: 2px solid $color-off-black;
        outline: 1px solid $color-off-black;
      }
      p {
        margin: 0;
      }
      .product-brief__footer & {
        flex-basis: 49%;
        height: auto;
        min-height: 38px;
        font-size: 13px;
        line-height: get-line-height(13px, 15px);
        .sku-menu-chip__price {
          display: none;
        }
      }
    }
    .product-quickshop & {
      display: none;
    }
    @if $pdp_modernized_design == true {
      &__price {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .select-box {
    &__options {
      overflow: hidden;
    }
  }
  &__shaded {
    .select-box {
      &__options {
        overflow: auto;
      }
    }
  }
}
